import React, { Component } from "react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Button,
  SelectOption,
  Spinner,
  Animation,
 ProgressBar } from "@hai/ui-react";
import axios from "../../../infra/axios";
import * as sortHelper from '../sortHelper';

class HaivisionLicenseOptionUpgradeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      license: this.props.license,
      versions: null,
      selectedVersion:null,
    };

    this.getProduct(this.state.license.productId);
  }

  getProduct = (productId) => {
    //setLoading(true);
    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Products/" +
          productId
      )
      .then((response) => {
        //setLicense(response.data);
        var versions = this.sortByVersionDesc(
          response.data?.productType?.productVersions
        ).filter((x)=>x.active === true);
        var selectedVersion =
          versions.length >= 1 ? versions[0] : null;

        this.setState((prev) => {
            prev.versions = versions;
            prev.selectedVersion = selectedVersion;
            return prev;
        });
               
        //setLoading(false);      
      });
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => {
      prev.show = nextProps.show;
      prev.license = nextProps.license;
      if(prev.license.productId !== nextProps.license.productId){
        this.getProduct(nextProps.license.productId);
      }
      return prev;
    });
  }

  sortByVersionDesc = (productVersions) => {
    if (productVersions?.length >= 1) {
      sortHelper.sortByVersionDesc(productVersions);
    }
    return productVersions;
  };

  handleSubmit() {
    this.setState((prev) => {
      prev.loading = true;
      return prev;
    });

    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Licenses/" +
          this.state.license.id +
          "/Upgrade/" +
          this.state.selectedVersion.id
      )
      .then((response) => {
        this.handleClose();
      });
  }

  handleClose() {
    this.props.onHide();
    this.props.refresh();
  }

  render() {
    return (
      <div>
        <Modal show={this.state.show} size="lg">
          <ModalHeader>
            <ModalTitle>Upgrade A License</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {this.state.license && (
              <>
                <FormGroup>
                  <FormLabel>Current Version</FormLabel>
                  <FormControl
                    disabled={true}
                    style={{ width: "380px" }}
                    defaultValue={this.state.license.productVersionNumber}
                  ></FormControl>
                  <FormLabel>Upgrade To Version</FormLabel>
                  <FormControl
                    as="select"
                    style={{ width: "380px" }}
                    defaultValue={
                      this.state.selectedVersion?.majorVersion +
                      "." +
                      this.state.selectedVersion?.minorVersion +
                      "." +
                      this.state.selectedVersion?.patchVersion
                    }
                    onChange={(e) => {
                      var text = e.target.value;
                      this.setState((prev) => {
                        prev.selectedVersion = prev.versions.filter(
                          (x) =>
                            x.majorVersion +
                              "." +
                              x.minorVersion +
                              "." +
                              x.patchVersion ===
                            text
                        )[0];
                        return prev;
                      });
                    }}
                  >
                    {this.state.versions &&
                      this.state.versions.map((ver) => {
                        return (
                          <SelectOption key={ver.id} value={ver?.majorVersion+"."+ver?.minorVersion+"."+ver?.patchVersion}>
                            {" "}
                            {ver.majorVersion}.{ver.minorVersion}.
                            {ver.patchVersion}
                          </SelectOption>
                        );
                      })}
                  </FormControl>
                </FormGroup>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={() => this.handleSubmit()}>
              Submit
            </Button>{" "}
            <Button onClick={() => this.handleClose()}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default HaivisionLicenseOptionUpgradeModal;
